import {get} from './cookie.js';

/**
 * Хелпер для построения урлов на поиск
 */
export default {
	create(abroadData, position, autoId, withPopup = false){
		let abroad = false,
				ipp = Number(get("ipp")) || 10,
				page = Math.floor(position / ipp) || 0;

		if (abroadData && (abroadData.abroad || abroadData.company.type_id === 3)) {
			abroad = true;
		}

		return `${abroad ? '/search-order-auto' : '/search'}/?brand.id[0]=${abroadData.brandId}&model.id[0]=${abroadData.modelId}&scrollToAuto=${autoId}&page=${page}${abroadData.custom ? '&abroad.not=-1&custom.not=-1':''}${withPopup ? '&levelsPopup=' + autoId : ''}`;
	}
}