import {mapActions, mapGetters} from 'vuex';
const PERIOD = 60 * 60 * 24 * 30;
const {set, get} = require('../../../helpers/cookie');

export default {
	data: function () {
		return {
			userSegment: undefined,
			autoCategory: undefined,
			vin: undefined,
			forecast: undefined,
			currentPosition: undefined,
			level: this.$route.query.level || 0
		};
	},
	mounted() {
		this.setWithVinPercent();
		this.vin = this.autoInfo.VIN;
		this.autoCategory = Number(this.autoInfo.category.id) || undefined;
		if (this.vin === '' && this.autoCategory === 1) {
			this.getCurrentPosition({top: this.level, autoId: this.autoInfo.id, brandId: this.autoInfo.brand.id, modelId: this.autoInfo.model.id, promiseVIN: 1, category_id: this.autoCategory })
				.then((data)=>{
					this.forecast = data.position;
				});
			this.getCurrentPosition({top: this.level, autoId: this.autoInfo.id, brandId: this.autoInfo.brand.id, modelId: this.autoInfo.model.id, promiseVIN: 0, category_id: this.autoCategory })
				.then((data)=>{
					this.currentPosition = data.position;
				});
		}
	},
	methods: {
		sendAlert() {
			this.gaEvent('congratulation_step', 'click_on_infoblock', 'Add_VIN');
		},
		...mapActions({
			getCurrentPosition: 'congratulations/getCurrentPosition',
			setWithVinPercent: 'others/withVinPercent'
		})
	},
	computed: {
		...mapGetters({
			autoInfo: 'congratulations/autoInfo',
			withVinPercent: 'others/withVinPercent',
		})
	},
	i18n: {
		messages: {
			uk: {
				'Авто з VIN-кодом продають швидше': 'Авто з VIN-кодом продають швидше',
				'Вказати VIN-код': 'Вказати VIN-код',
			},
			ru: {
				'Авто з VIN-кодом продають швидше': 'Авто с VIN-кодом продают быстрее',
				'Вказати VIN-код': 'Указать VIN-код',
			}
		}
	}

};
