export default {
	i18n: require('./i18n').default,
	data: function () {
		return {
			scrolled: false
		};
	},
	name: 'ScrollTopButton',
	methods: {
		scrollToTop: function () {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		handleScroll() {
			this.scrolled = window.scrollY > 0;
		}
	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	}
};
