import { mapGetters } from 'vuex';
import searchUrl from '../../../helpers/searchUrl';
import Evaluation from '../../Cabinet/components/Evaluation/index.vue';

export default {
  data() {
    return {
      title: '',
      mainPhotoSrc: '',
      price: '',
      priceUAH: '',
      location: '',
      gearbox: '',
      engine: '',
      fuel: '',
      race: '',
      currency: '',
      photoLoaded: false,
      links: {
        auto: '',
        toSearch: '',
        republication: '',
        photo: '',
      },
      sharingNetworks: {
        fb(purl) {
          let url = 'http://www.facebook.com/sharer.php?';
          url += `p[url]=${encodeURIComponent(`${purl}?utm_source=facebook&utm_medium=share_congratulations_step&utm_campaign=ads_bu&r_audience=smo&r_source=facebook&r_medium=share_congratulations_step&r_campaign=ads_bu`)}`;
          url += `&u=${encodeURIComponent(`${purl}?utm_source=facebook&utm_medium=share_congratulations_step&utm_campaign=ads_bu&r_audience=smo&r_source=facebook&r_medium=share_congratulations_step&r_campaign=ads_bu`)}`;
          url += '&s=100';
          return url;
        },
        tg(purl) {
          let url = 'https://t.me/share/url?';
          url += `url=${encodeURIComponent(`${purl}?utm_source=telegram&utm_medium=share_congratulations_step&utm_campaign=ads_bu&r_audience=smo&r_source=telegram&r_medium=share_congratulations_step&r_campaign=ads_bu`)}`;
          return url;
        },
        vb(purl) {
          let url = 'viber://forward?';
          url += `text=${encodeURIComponent(`${purl}?utm_source=viber&utm_medium=share_congratulations_step&utm_campaign=ads_bu&r_audience=smo&r_source=viber&r_medium=share_congratulations_step&r_campaign=ads_bu`)}`;
          return url;
        },
      },
    };
  },
  mounted() {
    if (Object.keys(this.autoInfo).length) {
      if (this.autoInfo.photos && !this.autoInfo.photos.main) {
        this.mainPhotoSrc = 'https://img6.auto.ria.com/images/nophoto/no-photo-380x250';
        this.links.photo = `/addauto/autoinfo/autoId/${this.autoInfo.id}/#PhotoSection`;
      }
    }

    if (this.position) {
      this.links.toSearch = searchUrl.create(this.abroadData, this.position, this.autoId, true);
    }

    if (this.autoId) {
      this.links.republication = `/addauto/republication/autoId/${this.autoId}/`;
    }

    let userAutosMainData = {};
    if (_TARGET_ === 'client') {
      try {
        if (this.action === 'autoRenewed') {
          userAutosMainData = JSON.parse(window.localStorage.userAutosMainData);
          if (userAutosMainData[this.autoId]) {
            this.title = [
              userAutosMainData[this.autoId]?.brand?.name,
              userAutosMainData[this.autoId]?.model?.name,
              userAutosMainData[this.autoId]?.year,
            ].join(' ');

            if (Number(userAutosMainData[this.autoId]?.photos?.main?.id)) {
              this.mainPhotoSrc = [
                'https://cdn3.riastatic.com/photosnew/auto/photo/',
                userAutosMainData[this.autoId]?.brand?.name?.toLowerCase().replace(/( |-)/g, '_'), '_',
                userAutosMainData[this.autoId]?.model?.name?.toLowerCase().replace(/( |-)/g, '_'), '__',
                userAutosMainData[this.autoId]?.photos?.main?.id, 'm',
              ].join('');
            } else {
              this.mainPhotoSrc = 'https://img6.auto.ria.com/images/nophoto/no-photo-380x250';
              this.links.photo = `/addauto/autoinfo/autoId/${this.autoInfo.id}/#PhotoSection`;
            }

            this.links.auto = [
              this.langCode ? `/${this.langCode}` : '',
              '/auto_',
              userAutosMainData[this.autoId]?.brand?.name?.toLowerCase().replace(/( |-)/g, '_'), '_',
              userAutosMainData[this.autoId]?.model?.name?.toLowerCase().replace(/( |-)/g, '_'), '_',
              this.autoInfo.id, '.html',
            ].join('');

            this.price = userAutosMainData[this.autoId]?.price?.value;
            this.priceUAH = userAutosMainData[this.autoId]?.price?.valueUAH;
            this.location = userAutosMainData[this.autoId]?.city?.name;
            this.gearbox = userAutosMainData[this.autoId]?.gearbox?.name;
            this.fuel = userAutosMainData[this.autoId]?.fuel?.name;
            this.engine = userAutosMainData[this.autoId]?.engine;
            this.race = userAutosMainData[this.autoId]?.race;

            switch (Number(userAutosMainData[this.autoId].price.currency.id)) {
              case 1:
                this.currency = '$';
                break;
              case 2:
                this.currency = '€';
                break;
              case 3:
                this.currency = 'грн';
                break;
              default:
                this.currency = '$';
                break;
            }
          } else {
            console.warn('Does not set userAutosMainData[this auto] from Local Storage');
          }
        }
      } catch (e) {
        console.warn('Does not set userAutosMainData from Local Storage');
      }
    }
  },
  created() {
    if (Object.keys(this.autoInfo).length && this.action !== 'autoRenewed') {
      this.title = [
        this.autoInfo?.brand && this.autoInfo?.brand?.name, this.autoInfo?.model && this.autoInfo?.model?.name,
        this.autoInfo?.year,
      ].join(' ');

      if (this.autoInfo.photos && this.autoInfo.photos.main) {
        this.mainPhotoSrc = [
          'https://cdn3.riastatic.com/photosnew/auto/photo/', this.autoInfo?.brand && this.autoInfo?.brand?.eng?.replace('-', '_'), '_',
          this.autoInfo?.model && this.autoInfo?.model?.eng?.replace('-', '_'), '__', this.autoInfo?.photos?.main?.id, 'm',
        ].join('');
      }

      this.links.auto = [
        this.langCode ? `/${this.langCode}` : '',
        '/auto_',
        this.autoInfo?.brand && this.autoInfo?.brand?.eng?.replace('-', '_'),
        '_',
        this.autoInfo?.model && this.autoInfo?.model?.eng?.replace('-', '_'),
        '_', this.autoInfo?.id, '.html',
      ].join('');

      this.price = this.autoInfo?.price && this.autoInfo?.price?.all?.USD?.value;
      this.priceUAH = this.autoInfo?.price && this.autoInfo?.price?.all?.UAH?.value;
      this.location = this.autoInfo?.location?.city?.name;
      this.gearbox = this.autoInfo?.gearbox?.name;
      this.fuel = this.autoInfo?.fuel?.name;
      this.engine = this.autoInfo?.engine?.volume?.liters;
      this.race = this.autoInfo?.race;
      this.currency = '$';
    }
  },
  name: 'AutoBlock',
  components: {
    evaluation: Evaluation,
  },
  computed: {
    ...mapGetters({
      autoInfo: 'congratulations/autoInfo',
      action: 'congratulations/action',
      GAeventName: 'congratulations/GAeventName',
      args: 'congratulations/args',
      abroadData: 'congratulations/abroadData',
      position: 'congratulations/position',
      autoId: 'congratulations/autoId',
      langCode: 'lang/code',
    }),
    afterNewPublicationStep() {
      return this.$route.query?.reference === 'v2';
    },
    expirationDate() {
      return this.autoInfo?.publication && this.autoInfo?.publication?.expiredAt || 0;
    },
    expirationLevels() {
      return this.autoInfo?.levels && this.autoInfo?.levels?.active && this.autoInfo?.levels?.active?.expiredAt || 0;
    },
    topLevels() {
      return this.autoInfo?.levels && this.autoInfo?.levels?.active && this.autoInfo?.levels?.active?.value || 0;
    },
  },
  watch: {
    mainPhotoSrc(n, o) {
      this.photoLoaded = true;
    },
  },
  methods: {
    sharing(socNet) {
      const urlForShare = this.sharingNetworks[socNet](`https://auto.ria.com${this.links.auto}`);
      this.popup(urlForShare);
    },
    popup(url) {
      const win = window.open(url, '', 'toolbar=0,status=0,width=600,height=436');
    },
  },
  i18n: {
    messages: {
      uk: {
        'Ваше оголошення': 'Ваше оголошення',
        'Оголошення буде опубліковано після підтвердження оплати': 'Оголошення буде опубліковано після підтвердження оплати',
        'Оголошення опубліковано за рахунок безкоштовних публікацій. Авто з’явиться у пошуку протягом': 'Оголошення опубліковано за рахунок безкоштовних публікацій. Авто з’явиться у пошуку протягом',
        'Оголошення буде опубліковано повторно після підтвердження оплати': 'Оголошення буде опубліковано повторно після підтвердження оплати',
        'Оголошення опубліковано повторно за рахунок безкоштовних публікацій': 'Оголошення опубліковано повторно за рахунок безкоштовних публікацій',
        'Оголошення відновлено та з’явиться у пошуку протягом': 'Оголошення відновлено та з’явиться у пошуку протягом',
        'Всі зміни збережені і з’являться у Вашій пропозиції через': 'Всі зміни збережені і з’являться у Вашій пропозиції через',
        'Дата публікації і рівні ТОП збережені': 'Дата публікації і рівні ТОП збережені',
        'Дата публікації збережена': 'Дата публікації збережена',
        'Термін дії публікації': 'Термін дії публікації',
        'Налаштувати перепублікацію': 'Налаштувати перепублікацію',
        'Рекламувати оголошення': 'Рекламувати оголошення',
        хвилин: 'хвилин',
        днів: 'днів | день | дні | днів',
        місяців: 'місяців | місяць | місяці | місяців',
        день: '0 днів | {count} день | {count} дні | {count} днів',
        часов: '0 годин | {count} година | {count} години | {count} годин',
        минут: '0 хвилин | {count} хвилина | {count} хвилини | {count} хвилин',
        ще: 'ще',
        'вищих місцях в пошуку': 'вищих місцях в пошуку',
        Поділитись: 'Поділитись',
        оголошенням: 'оголошенням',
        'тис. км': 'тис. км',
        'Оголошення опубліковане на': 'Оголошення опубліковане на',
        'Оголошення переопубліковано ще на': 'Оголошення переопубліковано ще на',
        'Після підтвердження оплати оголошення буде опубліковано, а всі кошти за публікацію — передано на ЗСУ.': 'Після підтвердження оплати оголошення буде опубліковано, а всі кошти за публікацію — передано на ЗСУ.',
        'Здобудемо перемогу разом!': 'Здобудемо перемогу разом!',
        'Оголошення буде опубліковано безкоштовно': 'Оголошення буде опубліковано безкоштовно',
        'Авто з’явиться у пошуку протягом': 'Авто з’явиться у пошуку протягом',
        'Ваша пропозиція оновиться протягом': 'Ваша пропозиція оновиться протягом',
        'Публікація буде доступна покупцям': 'Публікація буде доступна покупцям',
        'Щоб піднятись на вищі місця в пошуку, прорекламуйте оголошення': 'Щоб піднятись на вищі місця в пошуку, прорекламуйте оголошення',
        'Ваша пропозиція з’явиться в пошуку': 'Ваша пропозиція з’явиться в пошуку',
        протягом: 'протягом',
        'після підтвердження оплати': 'після підтвердження оплати',
      },
      ru: {
        'Ваше оголошення': 'Ваше предложение',
        'Оголошення буде опубліковано після підтвердження оплати': 'Объявления будет опубликовано после подтверждения оплаты',
        'Оголошення опубліковано за рахунок безкоштовних публікацій. Авто з’явиться у пошуку протягом': 'Объявления опубликовано за счет бесплатных публикаций. Авто появится в поиске в течение',
        'Оголошення буде опубліковано повторно після підтвердження оплати': 'Объявления будет опубликовано повторно после подтверждения оплаты',
        'Оголошення опубліковано повторно за рахунок безкоштовних публікацій': 'Объявления опубликовано повторно за счет бесплатных публикаций',
        'Оголошення відновлено та з’явиться у пошуку протягом': 'Объявления восстановлено и появится в поиске в течение',
        'Всі зміни збережені і з’являться у Вашій пропозиції через': 'Все изменения сохранены и появятся в Вашем предложении через',
        'Дата публікації і рівні ТОП збережені': 'Дата публикации и уровни ТОП сохранены',
        'Дата публікації збережена': 'Дата публикации сохранена',
        'Термін дії публікації': 'Срок действия публикации',
        'Налаштувати перепублікацію': 'Настроить перепубликацию',
        'Рекламувати оголошення': 'Рекламировать объявление',
        хвилин: 'минут',
        днів: 'дней | день | дня | дней',
        місяців: 'месяцев | месяц | месяца | месяцев',
        день: '0 дней | {count} день | {count} дня | {count} дней',
        часов: '0 часов | {count} час | {count} часа | {count} часов',
        минут: '0 минут | {count} минута | {count} минуты | {count} минут',
        ще: 'еще',
        'вищих місцях в пошуку': 'высших местах в поиска',
        Поділитись: 'Поделиться',
        оголошенням: 'объявлением',
        'тис. км': 'тыс. км',
        'Оголошення опубліковане на': 'Объявление опубликовано на',
        'Оголошення переопубліковано ще на': 'Объявления переопубликовано еще на',
        'Після підтвердження оплати оголошення буде опубліковано, а всі кошти за публікацію — передано на ЗСУ.': 'После подтверждения оплаты объявление будет опубликовано, а все средства за публикацию — переданы на ВСУ.',
        'Здобудемо перемогу разом!': 'Одержим победу вместе!',
        'Оголошення буде опубліковано безкоштовно': 'Объявление будет опубликовано бесплатно',
        'Авто з’явиться у пошуку протягом': 'Авто появится в поиске в течение',
        'Ваша пропозиція оновиться протягом': 'Ваше предложение обновится в течение',
        'Публікація буде доступна покупцям': 'Публикация будет доступна покупателям',
        'Щоб піднятись на вищі місця в пошуку, прорекламуйте оголошення': 'Чтобы подняться на высшие места в поиске, прорекламируйте объявление',
        'Ваша пропозиція з’явиться в пошуку': 'Ваше предложение появится в поиске',
        протягом: 'в течение',
        'після підтвердження оплати': 'после подтверждения оплаты',
      },
    },
  },
};
