import {mapGetters} from 'vuex';

export default {
	data: function () {
		return {
		};
	},
	name: 'HeadingBlock',
	computed: {
		...mapGetters({
			action: 'congratulations/action'
		})
	},
	i18n: {
		messages: {
			uk: {
				'Оголошення': 'Оголошення',
				'майже опубліковано': 'майже опубліковано',
				'опубліковано': 'опубліковано',
				'майже переопубліковано': 'майже переопубліковано',
				'переопубліковано': 'переопубліковано',
				'відновлено': 'відновлено',
				'відредаговано': 'відредаговано'
			},
			ru: {
				'Оголошення': 'Объявление',
				'майже опубліковано': 'почти опубликовано',
				'опубліковано': 'опубликовано',
				'майже переопубліковано': 'почти переопубликовано',
				'переопубліковано': 'переопубликовано',
				'відновлено': 'восстановлено',
				'відредаговано': 'отредактировано'
			}
		}
	}
};
