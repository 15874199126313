import {mapGetters} from 'vuex';
import betweenDate from "../../../mixins/betweenDate";
const {get} = require('../../../helpers/cookie');

export default {
	name: 'OptionsBlock',
	data(){
		return{
			promoBanner: false,
		};
	},
	mixins: [betweenDate],
	computed: {
		...mapGetters({
			GAeventName: 'congratulations/GAeventName',
		})
	},
	mounted() {
		this.setTime('2022-04-11 00:00', '2022-04-17 23:59')
		if(this.showBetweenDateTime || Number(get('increased_refill_bonus'))){
			this.promoBanner = true
		}
	},
	i18n: {
		messages: {
			uk: {
				'Публікуйте свої авто з вигодою': 'Публікуйте свої авто з вигодою',
				'Публікувати з вигодою до': 'Публікувати з вигодою до',
				'Дивитись мої оголошення': 'Дивитись мої оголошення',
				'Додати ще авто': 'Додати ще авто',
				'До 50% вигоди на публікації і ТОПи': 'До 50% вигоди на публікації і ТОПи',
				'Поповніть, щоб отримати': 'Поповніть, щоб отримати',
			},
			ru: {
				'Публікуйте свої авто з вигодою': 'Публикуйте свои авто с выгодой',
				'Публікувати з вигодою до': 'Публиковать с выгодой до',
				'Дивитись мої оголошення': 'Смотреть мои объявления',
				'Додати ще авто': 'Добавить еще авто',
				'До 50% вигоди на публікації і ТОПи': 'До 50% выгоды на публикации и ТОПы',
				'Поповніть, щоб отримати': 'Пополните, чтобы получить',
			}
		}
	}
};
