import {mapGetters} from "vuex";

export default {
	name: "PreFooter",
	computed: {
		...mapGetters({
			rand2: 'others/rand2',
			GAeventName: "congratulations/GAeventName"
		})
	}
}