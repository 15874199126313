/* eslint-disable import/no-extraneous-dependencies,global-require */
import { mapActions, mapGetters } from 'vuex';
import storeModule from '../../store/congratulations';
import HeadingBlock from './HeadingBlock/index.vue';
import AutoBlock from './AutoBlock/index.vue';
import AutotestBlock from './AutotestBlock/index.vue';
import OptionsBlock from './OptionsBlock/index.vue';
import RankingChangeInfo from './RankingChangeInfo/index.vue';
import PreFooter from '../Common/PreFooter/index.vue';
import ScrollTopButton from '../Common/ScrollTopButton/index.vue';

function getArgs(query) {
  const argRestoreFromArchive = query.restore_from_archive || 0;
  return {
    argFree: query.free || 0,
    argPublish: query.publish || 0,
    argRepublish: query.republish || 0,
    argAction: query.action,
    argRestoreFromArchive,
    argPriceReason: Number(query.priceReason) || 0,
    args: {
      level: Number(query.level) || 0,
      levelPeriod: Number(query.period) || 0,
      publicationPeriod: Number(query.publicationPeriod) || 1,
      publicationDays: Number(query.publicationDays) || 0,
      pricePublication: Number(query.pubPrice) || 0,
      priceLevel: Number(query.levelPrice) || 0,
      restoreFromArchive: argRestoreFromArchive,
    },
  };
}

export default {
  data() {
    return {
      withoutVIN: false,
      autoCategory: undefined,
      vin: undefined,
      sendConversionPrice: true,
      agro: false,
    };
  },
  mixins: [require('../../mixins/userNavigationLogger')],
  init({ store, route }) {
    store.registerModule('congratulations', storeModule);
    let action = 'paid';
    const { autoId } = route.to.params;
    const {
      argFree, argPublish, argRepublish, argRestoreFromArchive, argAction, argPriceReason, args,
    } = getArgs(route.to.query);
    if (Number(argFree)) {
      // определяем платная или бесплатная операция
      action = 'free';
    }
    if (argPriceReason === 22) {
      // рестрікшн для тріал публікації на 14 днів
      action = 'trial';
    }
    if (Number(argRepublish)) {
      // перепубликация
      action += 'Republish';
      store.dispatch('congratulations/setTypeStep', { typeStep: 2 });
    }
    if (Number(argPublish)) {
      // публикация
      action += 'Publish';
      store.dispatch('congratulations/setTypeStep', { typeStep: 1 });
    }
    if (Number(argRestoreFromArchive)) {
      // востановление из архива
      action = 'Recovery';
      store.dispatch('congratulations/setTypeStep', { typeStep: 4 });
    }
    if (argAction) {
      // редагування
      action = argAction;
      store.dispatch('congratulations/setTypeStep', { typeStep: 3 });
    }
    if (argPriceReason === 20) {
      // Платна публікація на користь Збройних сил України
      action = `${argRepublish ? 'Republish' : 'Publish'}ZSU`;
      store.dispatch('congratulations/setTypeStep', { typeStep: argRepublish ? 2 : 1 });
    }

    return Promise.all([
      store.dispatch('congratulations/autoInfo', { action, args, autoId })
        .catch((error) => {
          console.error('ERROR:::congratulations/autoInfo', error.toString());
          throw error;
        }),
      store.dispatch('congratulations/autotestInfo', { autoId })
        .catch((error) => {
          console.error('ERROR:::congratulations/autotestInfo', error.toString());
          throw error;
        }),
      store.dispatch('congratulations/loadSegment')
        .catch((error) => {
          console.error('ERROR:::congratulations/loadSegment', error.toString());
          throw error;
        }),
      store.dispatch('congratulations/abroadData', { autoId })
        .then(() => {
          const {
            brandId, modelId, abroad: abroadInit, company, custom,
          } = store.getters['congratulations/abroadData'];
          if (Object.keys(store.getters['congratulations/abroadData']).length) {
            let abroad = abroadInit;
            if (company) {
              abroad = !!((abroadInit || company.typeId === 3));
            }
            return Promise.all([store.dispatch('congratulations/position', {
              autoId,
              brandId,
              modelId,
              abroad,
              custom,
            })]);
          }
          return undefined;
        })
        .catch((error) => {
          console.error('ERROR:::congratulations/abroadData', error.toString());
          throw error;
        }),
    ]);
  },
  components: {
    'heading-part': HeadingBlock,
    'auto-block': AutoBlock,
    'auto-test-block': AutotestBlock,
    'pre-footer': PreFooter,
    'scroll-top-button': ScrollTopButton,
    'ranking-change-info': RankingChangeInfo,
    'options-block': OptionsBlock,
  },
  computed: {
    ...mapGetters({
      GAeventName: 'congratulations/GAeventName',
      autotestData: 'congratulations/autotestInfo',
      autoInfo: 'congratulations/autoInfo',
      typeStep: 'congratulations/typeStep',
      segment: 'Kind16Check/segment',
      userData: 'Common/userData',
    }),
    autotestDataExist() {
      return Number(this.autotestData?.sto)
        || Number(this.autotestData?.mreo)
        || this.autotestData?.vin;
    },
  },
  watch: {
    userData() {
      this.sendGTM();
    },
  },
  methods: {
    ...mapActions({
      fetchLeasingBlock: 'congratulations/leasingBlock',
      setSegment: 'Kind16Check/loadSegment',
    }),
    formDataEventUserNavigation(url, prevUrl, point, prevPoint, pathIds = '') {
      return {
        event_id: 697,
        screentype: this.isDesktop ? 1 : 2,
        current_url: encodeURIComponent(url),
        previous_url: encodeURIComponent(prevUrl),
        proposal_id: this.$route.params.autoId,
        lang_id: this.langId,
        proposal_action_type: this.typeStep,
        current_point_action: point.length > 0 ? encodeURIComponent(point) : undefined,
        previous_point_action: prevPoint.length > 0 ? encodeURIComponent(prevPoint) : undefined,
        search_text: pathIds.length > 0 ? encodeURIComponent(pathIds) : undefined,
      };
    },
    sendGTM() {
      if (this.sendConversionPrice) {
        try {
          const { userEmail, userPhones = [], userId } = this.userData;
          const [userPhone = ''] = userPhones;
          const { argFree, args } = getArgs(this.$route.query);
          if (userId) {
            const gtmData = {
              Value_add: !Number(argFree) ? args.publicationPeriod * args.pricePublication : 0,
              Value_level: args.level * args.levelPeriod * args.priceLevel,
              user_phone: userPhone.replace(/(\s|\(|\))/g, '') || '',
              user_mail: userEmail || 'googleads@test.com',
            };
            this.$gtm.trackEvent({ event: 'conversion_price', ...gtmData });
            this.sendConversionPrice = false;
          }
        } catch (e) {
          console.error('=== ERROR on sendConversionPrice ===', e.toString());
        }
      }
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.setSegment();
    this.autoCategory = Number(this.autoInfo.category.id) || undefined;
    if (this.autoInfo.VIN === '' && this.autoCategory === 1) {
      this.withoutVIN = true;
    }

    const isTractorAdvert = this.autoCategory === 4 && Number(this.autoInfo.body.id) === 384;
    const isAgriculturalAdvert = this.autoCategory === 10;

    // AGRO
    if (isAgriculturalAdvert || isTractorAdvert) {
      this.agro = true;
    }

    this.fetchLeasingBlock({ autoId: this.$route.params.autoId });

    this.sendGTM();
  },
  name: 'StepCongratulations',
  metaInfo() {
    return {
      title: 'AUTO.RIA™ — Шаг поздравления',
      meta: [
        { name: 'description', content: '' },
        { name: 'keywords', content: '' },
        { property: 'og:title', content: '' },
        { property: 'og:description', content: '' },
        { name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/' },
      ],
    };
  },
  i18n: {
    messages: {
      uk: {
        'Оголошення не може бути переопубліковано': 'Оголошення не може бути переопубліковано',
        'Тепер уся с/г техніка на': 'Тепер уся с/г техніка на',
        'Вас і ваших покупців там розуміють краще': 'Вас і ваших покупців там розуміють краще',
      },
      ru: {
        'Оголошення не може бути переопубліковано': 'Объявление не может быть переопубликовано',
        'Тепер уся с/г техніка на': 'Теперь вся с/х техника на',
        'Вас і ваших покупців там розуміють краще': 'Вас и ваших покупателей там понимают лучше',
      },
    },
  },
};
