import {mapGetters} from 'vuex';

export default {
	data: function () {
		return {
			links: {
				lawyerCheck: '',
				technicalCheck: '',
				vinCheck: '',
				more: ''
			}
		};
	},
	name: 'AutotestBlock',
	mounted() {
		if (this.autoId) {
			this.links.more = `/check_selection/${this.autoId}/`;
			this.links.lawyerCheck = `/check_selection/${this.autoId}/#juristicalVerification`;
			this.links.technicalCheck = `/check_selection/${this.autoId}/#technicalVerifications`;
			this.links.vinCheck = `/check_selection/${this.autoId}/#vinVerification`;
		}
	},
	computed: {
		...mapGetters({
			autotestData: 'congratulations/autotestInfo',
			GAeventName: 'congratulations/GAeventName',
			autoId: 'congratulations/autoId'
		})
	},
	i18n: {
		messages: {
			uk: {
				'Розкажіть більше про авто з розширеними перевірками': 'Розкажіть більше про авто з розширеними перевірками',
				'Перевірити': 'Перевірити',
				'Технічно': 'Технічно',
				'Юридично': 'Юридично',
			},
			ru: {
				'Розкажіть більше про авто з розширеними перевірками': 'Расскажите больше о авто с расширенными проверками',
				'Перевірити': 'Проверить',
				'Технічно': 'Технически',
				'Юридично': 'Юридически',
			}
		}
	}
};
