export default {
	messages: {
		ru: {
			'наверх': 'наверх'
		},
		uk: {
			'наверх': 'вгору'
		}
	}
};
